export const newsfilterPageData = {
    title: 'News Articles',
    breadcrumbs: [
        { title: 'Home', pageSlug: 'homepage' },
        {
          title: 'News Articles',
          pageSlug: 'filter',
          isActive: true
        }
    ],
    hasImage: false
};

export const profilefilterPageData = {
  title: 'Our Team',
  breadcrumbs: [
      { title: 'Home', pageSlug: 'homepage' },
      {
        title: 'Our Team',
        pageSlug: 'filter',
        isActive: true
      }
  ],
  hasImage: false
};
