import React from 'react';
import Layout from '~layouts/layout';
import { RenderedContext } from '~layouts/layout';
import Header from '~2-components/Header/Header';
import { newsfilterPageData } from '~data/filterPageData';
import { allNewsArticleData } from '~/data/allNewsArticleData';
import AllNewsArticleList from '~/2-components/AllNewsArticleList/AllNewsArticleList';

const AllNewsArticle = () => (
    <Layout>
        <RenderedContext.Provider value={newsfilterPageData}>
            <Header />
        </RenderedContext.Provider>

        <div className="page-content">
            <div className="container">
                <AllNewsArticleList articles={allNewsArticleData} />
            </div>
        </div>
    </Layout>
);

export default AllNewsArticle;
