export const allNewsArticleData = [
    {
        title: "A cosmetic conundrum",
        date: "12 January 2021",
        page_url: "a-cosmetic-conundrum"
    },
    {
        title: "Colleagues and contention",
        date: "8 December 2020",
        page_url: "colleagues-and-contention"
    },
    {
        title: "When an injury is not considered a ‘serious injury’",
        date: "1 December 2020",
        page_url: "when-an-injury-is-not-considered-a-serious-injury"
    },
    {
        title: "Bourke Street findings delivered",
        date: "19 November 2020",
        page_url: "bourke-street-findings-delivered"
    },
    {
        title: "Off-site work injury leads to compensation",
        date: "27 October 2020",
        page_url: "off-site-work-injury-leads-to-compensation"
    },
    {
        title: "Comcare claims on the rise",
        date: "20 October 2020",
        page_url: "comcare-claims-on-the-rise"
    },
    {
        title: "Funding grants to improve safety for cyclists and pedestrians",
        date: "1 September 2020",
        page_url: "funding-grants-to-improve-safety-for-cyclists-and-pedestrians"
    },
    {
        title: "Injured workers' success despite unreasonable terminations | Four Corners",
        date: "7 August 2020",
        page_url: "injured-workers-success-despite-unreasonable-terminations-four-corners"
    },
    {
        title: "Is deep vein thrombosis covered by WorkCover?",
        date: "4 August 2020",
        page_url: "is-deep-vein-thrombosis-covered-by-workcover"
    },
    {
        title: "Poor OH&S leading to serious injury and death",
        date: "16 June 2020",
        page_url: "poor-ohs-leading-to-serious-injury-and-death"
    },
    {
        title: "Fatality Free Friday 2020",
        date: "29 May 2020",
        page_url: "fatality-free-friday-2020"
    },
    {
        title: "Grace Bowran-Burge finalist in Lawyers Weekly 30 Under 30",
        date: "13 May 2020",
        page_url: "grace-bowran-burge-finalist-in-lawyers-weekly-30-under-30"
    }
];
